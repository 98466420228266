@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'RedHatDisplay';
    src: url('./fonts/RedHatDisplay-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'RedHatDisplay';
    src: url('./fonts/RedHatDisplay-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'RedHatDisplay';
    src: url('./fonts/RedHatDisplay-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'RedHatDisplay';
    src: url('./fonts/RedHatDisplay-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'RedHatDisplay';
    src: url('./fonts/RedHatDisplay-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'RedHatDisplay';
    src: url('./fonts/RedHatDisplay-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'RedHatDisplay';
    src: url('./fonts/RedHatDisplay-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'RedHatDisplay';
    src: url('./fonts/RedHatDisplay-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'RedHatDisplay';
    src: url('./fonts/RedHatDisplay-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'RedHatDisplay';
    src: url('./fonts/RedHatDisplay-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'RedHatDisplay';
    src: url('./fonts/RedHatDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'RedHatDisplay';
    src: url('./fonts/RedHatDisplay-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'RedHatDisplay';
    src: url('./fonts/RedHatDisplay-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'RedHatDisplay';
    src: url('./fonts/RedHatDisplay-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

* {
    font-family: 'RedHatDisplay', sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}



.right-bg {
    position: absolute;
    bottom: -30px;
    right: 0;
    height: 267px;
    width: 475px;
    background: linear-gradient(to bottom left, #DEFF34 25%, #33BBFF 50%, #8CF97C 75%, transparent 100%);
}

.hero {
    background-image: url("assets/img/hero-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;
}

.header {
    background-image: url("assets/img/header-bg.png");
    background-position: bottom;
    background-size: cover;
}

.mobile-nav {
    background-image: url("assets/img/nav-bg.png");
    background-position: center;
    background-size: cover;
}

.services-content {
    background-image: url("assets/img/services-bg.png");
    background-position: center;
    background-size: cover;
}


@layer components{
    .large-text {
        @apply font-bold pb-4;
        @apply text-[24px] md:text-[50px] text-center;

        &.limit {
            @apply max-w-[273px] md:max-w-full;
        }

        span {
            @apply text-[#01D3D8];
        }
    }
    .service-item {
        @apply shadow-xl w-full lg:max-w-[416px] flex flex-col p-5 md:px-5 md:py-5 lg:p-10 rounded-[20px];
        background-size: auto; /* Ensures the image covers the entire card */
        background-position: center; /* Centers the image within the card */
        background-repeat: no-repeat;

        &:nth-child(1) {
            background: url("assets/img/service-1.png") center;
        }

        &:nth-child(2) {
            background: url("assets/img/service-2.png") center;
        }

        &:nth-child(3) {
            background: url("assets/img/service-3.png") center;
        }


    }
}



